export const LOGIN_ROUTE = '/login'
export const CREOSLIST_ROUTE = '/creoList'
export const APPS_ROUTE = '/appsList'
export const HOME_ROUTE = '/home'
export const ADD_APP_ROUTE = '/form'
export const ADD_CREO_ROUTE = '/creo'
export const ADD_INTERACTIVE_ROUTE = '/interactiveTESTING'
export const STATS_ROUTE = '/info'
export const MOVING_ROUTE = '/moving'
export const DELETE_APP_ROUTE = '/delete'
export const ADMIN_ROUTE = '/admin'
export const MYCREOS_ROUTE = '/mycreos'
export const READYCREOS_ROUTE = '/readycreos'
export const MYAPPS_ROUTE = '/myapps'
export const INTERACTIVES_ROUTE = '/interactiveListTESTING'
export const GEOPOOL_ROUTE = '/geopool'
export const CREO_TEMPLATES_ROUTE = '/creoTemplates'
