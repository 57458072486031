import {$host} from './index.js'

export const addNewInteractive = async (interactiveName, file) => {
  const formData = new FormData();
  formData.append('interactiveName', interactiveName);
  formData.append('file', file);
  const {data} = await $host.post('/api/interactive/addNewInteractive', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export const getInteractives = async () => {
  const {data} = await $host.get('/api/interactive/getInteractives')
  return data
}