// import { React, useState, useEffect } from 'react'
// import SideNav, { Toggle, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
// import '@trendmicro/react-sidenav/dist/react-sidenav.css'
// import { useNavigate } from 'react-router-dom'
// import Loader from '../Loader'


// const NavBar = () => {
//     const navigate = useNavigate()
//   return (
//     <SideNav
//         onSelect={selected => {navigate('/'+selected)}}
//         style={{backgroundColor: '#404f6a', opacity: '0.9'}}
//     >
//         <SideNav.Toggle />
//         <SideNav.Nav defaultSelected="home">
//             <NavItem eventKey='home'>
//                 <NavIcon><i className='fa fa-fw fa-home' style={{fontSize: '1.5em'}}></i></NavIcon>
//                 <NavText>Home</NavText>
//             </NavItem>
//             <NavItem eventKey='appsList'>
//                 <NavIcon><i className='fa-solid fa-mobile-screen-button' style={{fontSize: '1.5em'}}></i></NavIcon>
//                 <NavText>Applications</NavText>
//             </NavItem>
//             <NavItem eventKey='creoList'>
//                 <NavIcon><i className='fa-solid fa-lightbulb' style={{fontSize: '1.5em'}}></i></NavIcon>
//                 <NavText>Creo</NavText>
//             </NavItem>
//             <NavItem eventKey='login'>
//                 <NavIcon><i className='fa-solid fa-arrow-right-from-bracket' style={{fontSize: '1.5em'}}></i></NavIcon>
//                 <NavText>Log out</NavText>
//             </NavItem>
//         </SideNav.Nav>
//     </SideNav>
//   )
// }

// export default NavBar




import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { SidebarData, SidebarDataTechHelper, SidebarDataCreoLead, SidebarDataLead, SidebarDataAdmin, SidebarDataCreoUnit } from './SidebarData'
import './NavBar.css'
import { IconContext } from 'react-icons'

function Navbar() {
  const userStore = useSelector(state => state.user.user)
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <>
      <IconContext.Provider value={{ color: 'undefined' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {userStore.role === 'TechHelper' ?
              SidebarDataTechHelper.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                )
              })
            :
              userStore.role === 'CreoLead' ?
                SidebarDataCreoLead.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  )
                })
              :
                userStore.role === 'Lead' ?
                  SidebarDataLead.map((item, index) => {
                    return (
                      <li key={index} className={item.cName}>
                        <Link to={item.path}>
                          {item.icon}
                          <span>{item.title}</span>
                        </Link>
                      </li>
                    )
                  })
                :
                  userStore.role === 'Admin' ?
                    SidebarDataAdmin.map((item, index) => {
                      return (
                        <li key={index} className={item.cName}>
                          <Link to={item.path}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Link>
                        </li>
                      )
                    })
                  :
                    userStore.role === 'CreoUnit' ?
                      SidebarDataCreoUnit.map((item, index) => {
                        return (
                          <li key={index} className={item.cName}>
                            <Link to={item.path}>
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          </li>
                        )
                      })
                    :
                      SidebarData.map((item, index) => {
                        return (
                          <li key={index} className={item.cName}>
                            <Link to={item.path}>
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          </li>
                        )
                      })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar