import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import {useTg} from '../hooks/useTg'
import { addArrayApps, resetApps } from '../../store/appsReducer'
import {deleteApp, getApps} from '../../http/appsApi'
import './Form.css'


const DeleteForm = () => {
    const appsStore = useSelector(state => state.apps.apps)
    const userStore = useSelector(state => state.user.user)
    // const dispatch = useDispatch()
    const [appName, setAppName] = useState('')

    const {tg, queryId} = useTg()

    const onSendData = useCallback(() => {
        if (userStore.role === 'Admin' || userStore.role === 'TechHelper') {
            if (appName === '') {
                alert('Заполни все поля')
            } else {
                const findApp = appsStore.find(el => el.appName === appName)
                    deleteApp(findApp._id).then(data => {
                        data.action = 'remove'
                        if (data) {
                            tg.sendData(JSON.stringify(data))
                            alert(`Приложение ${appName} удалено`)
                        } else {
                            alert('Ошибка')
                        }
                    })
            }
        } else {
            alert('Нет доступа')
        }
    }, [appName])

    // useEffect( () => {
    //     dispatch(resetApps())
    //     getApps().then(data => {
    //         if (data.length !== 0) {
    //             dispatch(addArrayApps(data))
    //         } 
    //     })
    //   }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const bannedApps = appsStore.filter(app => app.status === 'Бан')
    const options = bannedApps.map(app => ({ ...app, label: app.appName, value: app.appName, color: '#000' }))
    const appToDelete = bannedApps.find(el => el.appName === appName)

    return (
        <div className={'form'}>
            {userStore.role === 'Admin' || userStore.role === 'TechHelper' ?
                <>
                    <h3>Выбери приложение</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={options} 
                            onChange={(e) => setAppName(e.value)} 
                            placeholder={'Имя приложения'} 
                        />
                    </span>
                    <div className={'prevData'}>Информация о приложении</div>
                    <div className={'input'}>
                        {appToDelete !== undefined && (
                            <>
                                Выбранное приложение: {appName} <br/>
                                Поставщик: {appToDelete?.partner} <br/>
                                Возраст: {appToDelete?.age} <br/>
                                Статус: {appToDelete?.status}
                            </>
                        )}
                    </div>
                    <button className={'webBtnAdd'} onClick={onSendData}>Удалить из бота</button>
                </>
            :
                <h3>Нет доступа</h3>
            }
        </div>
    );
}

export default DeleteForm