import {$host} from './index.js'

export const addApp = async (partner, appName, link, age, platform, file) => {
    const formData = new FormData()
    formData.append('partner', partner)
    formData.append('appName', appName)
    formData.append('link', link)
    formData.append('age', age)
    formData.append('platform', platform)
    formData.append('file', file)
    const {data} = await $host.post('/api/addNewApp', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    return data
}

export const getApps = async () => {
    const {data} = await $host.get('/api/apps')
    return data
} 

export const getAvailableApps = async () => {
    const {data} = await $host.get('/api/availableApps')
    return data
} 

export const getAppsByPartner = async () => {
    const {data} = await $host.get('/api/getAppsByPartner')
    return data
} 

export const getWebsApps = async (username) => {
    const {data} = await $host.get('/api/webApps/' + username)
    return data
}

export const getBannedApps = async () => {
    const {data} = await $host.get('/api/bannedApps')
    return data
} 

export const deleteApp = async (_id) => {
    const {data} = await $host.delete('/api/deleteApp/' + _id)
    return data
} 

export const updateAppSetup = async (_id, partner, appName, web, link, age, status) => {
    const {data} = await $host.put('/api/updateAppSetup/' + _id, {partner, appName, web, link, age, status})
    return data
} 

export const updateAppByWeb = async (_id, appName, web, source, mode) => {
    const {data} = await $host.put('/api/updateAppByWeb/' + _id, {appName, web, source, mode})
    return data
}

export const updateAppToFree = async (_id, web, source) => {
    const {data} = await $host.put('/api/updateAppFree/' + _id, {web, source})
    return data
} 


