import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayTemplates, resetTemplates } from '../../store/templatesReducer'
import './CreoTemplates.css'
import CreoTemplateItem from '../CreoTemplateItem/CreoTemplateItem'
import { getUpdatedTemplates } from '../../http/templatesApi'
import Loader from '../Loader'


const CreoTemplates = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const templatesStore = useSelector(state => state.templates.templates)
    const [loading, setLoading] = useState(true)
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [clickedSort1, setClickedSort1] = useState(false)
    const [clickedSort2, setClickedSort2] = useState(false)
    const [clickedSort3, setClickedSort3] = useState(false)
    
    useEffect(() => {
        if (templatesStore.length === 0) {
            getUpdatedTemplates().then(data => {
                const sortedArr = data.sort((a, b) => (a.order > b.order) ? -1 : (a.order === b.order) ? ((a.order > b.order) ? -1 : 1) : 1)
                
                

                dispatch(addArrayTemplates(sortedArr))
                setLoading(false)
            })
        }
    }, [])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const sortItems = (e) => {
        switch (e.target.id) {
            case '1':
                setClickedSort2(false)
                setClickedSort3(false)
                if (!clickedSort1) {
                    setClickedSort1(!clickedSort1)
                    const data = templatesStore.sort((a, b) => b.orderedGeos.length - a.orderedGeos.length)
                } else {
                    setClickedSort1(!clickedSort1)
                    const data = templatesStore.sort((a, b) => a.orderedGeos.length - b.orderedGeos.length)
                }
                break;
            case '2':
                setClickedSort1(false)
                setClickedSort3(false)
                if (!clickedSort2) {
                    setClickedSort2(!clickedSort2)
                    const data = templatesStore.sort((a,b) => a.order > b.order ? -1 : 1)
                } else {
                    setClickedSort2(!clickedSort2)
                    const data = templatesStore.sort((a, b) => a.order > b.order ? 1 : -1)
                }
                break;
            case '3':
                setClickedSort1(false)
                setClickedSort2(false)
                if (!clickedSort3) {
                    setClickedSort3(!clickedSort3)
                    const data = templatesStore.sort((a, b) => b.creoNames.length - a.creoNames.length)
                } else {
                    setClickedSort3(!clickedSort3)
                    const data = templatesStore.sort((a, b) => a.creoNames.length - b.creoNames.length)
                }
                break;
            case '4':
                const data = templatesStore.sort((a, b) => (a.order > b.order) ? 1 : -1)
                dispatch(resetTemplates())
                dispatch(addArrayTemplates(data))

                setClickedSort1(false)
                setClickedSort2(false)
                setClickedSort3(false)
                break;
            default:
                break;
        }
    }

  return (
    // <>
    //     <Navbar />
        <div className={'content'}>
            {userStore.role === 'TechHelper' ?
                <h3>Нет доступа</h3>
            :
                <>
                    <div className={'sortTab'}>
                        <button id='1' className={clickedSort1 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По популярности</button>
                        <button id='2' className={clickedSort2 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По шаблону</button>
                        <button id='3' className={clickedSort3 ? 'webBtnSort active' : 'webBtnSort'} onClick={sortItems}>По количеству CP</button>
                        <button id='4' className={'webBtnSort'} onClick={sortItems}>Сброс</button>
                    </div>
                    <div className={'list'}>
                        {loading ?
                            <Loader />
                        :
                            templatesStore.map(elem => {
                                return (
                                    <CreoTemplateItem
                                        key={elem._id}
                                        item={elem}
                                        className={'itemCreo'}
                                        multiOptionGeo={multiOptionGeo}
                                    />
                                )   
                            })
                        }
                    </div>
                </>
            }
    </div>
    // </>
  )
}

export default CreoTemplates
