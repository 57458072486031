import { React } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './TooltipHTML.css'


const TooltipHTML = ({item, tooltipActive, setTooltipActive}) => {

  return (
    <div className={'container'}
      onClick={() => setTooltipActive(!tooltipActive)}>
        <LazyLoadImage
          alt = ''
          className={'img'} 
          src={'https://mathieubois.com/interactive/previews/' + item.interactive.replace('.html', '.jpg')}
        />
    </div>
  );
}

export default TooltipHTML