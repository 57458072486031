import { createStore, combineReducers} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authReducer } from './authReducer'
import { appsReducer } from './appsReducer'
import { myAppsReducer } from './myAppsReducer'
import { creosReducer } from './creosReducer'
import { filteredCreosReducer } from './filteredCreosReducer'
import { userReducer } from './userReducer'
import { partnersReducer } from './partnersReducer'
import { allUsersReducer } from './allUsersReducer'
import { geoPoolReducer } from './geoPoolReducer'
import { interactivesReducer } from './interactivesReducer'
import { filteredInteractivesReducer } from './filteredInteractivesReducer'
import { templatesReducer } from './templatesReducer'
import { filteredTemplatesReducer } from './filteredTemplatesReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    apps: appsReducer,
    myApps: myAppsReducer,
    creos: creosReducer,
    filteredCreos: filteredCreosReducer,
    interactives: interactivesReducer,
    filteredInteractives: filteredInteractivesReducer,
    user: userReducer,
    partners: partnersReducer,
    allUsers: allUsersReducer,
    geoPool: geoPoolReducer,
    templates: templatesReducer,
    filteredTemplates: filteredTemplatesReducer
})

const RESET = 'RESET'

const wrapRootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }

  return rootReducer(state, action);
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'apps', 'myApps', 'creos', 'filteredCreos', 'interactives', 'filteredInteractives', 'partners', 'geoPool', 'templates', 'filteredTemplates']
  }

const persistedReducer = persistReducer(persistConfig, wrapRootReducer)

export const resetApp = () => ({type: RESET})
export const store = createStore(persistedReducer, composeWithDevTools())
export const persistor = persistStore(store) 