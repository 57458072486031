import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import Select from 'react-select'
import { addArrayMyApps, resetMyApps } from '../../store/myAppsReducer'
import { updateMyApp } from '../../store/myAppsReducer'
import { getAvailableApps, updateAppToFree } from '../../http/appsApi'
import Modal from '../Modal/Modal'
import TooltipImage from '../Tooltip/TooltipImage'
import './MyApps.css'


const MyApps = () => {
    const dispatch = useDispatch()
    const myAppsStore = useSelector(state => state.myApps.myApps)
    const userStore = useSelector(state => state.user.user)
    // const appsInWork = appsStore.filter(el => Object.values(el.sources).flat().length !== 0)
    const [modalActive, setModalActive] = useState(false)
    const [findApp, setFindApp] = useState({})
    const [selectedSource, setSelectedSource] = useState([])
    const [sources, setSources] = useState([])

    useEffect( () => {
        dispatch(resetMyApps())
        getAvailableApps().then(data => {
            if (data.length !== 0) {
                const apps = data.filter(el => Object.values(el.sources).flat().length !== 0)
                const appsWeb = apps.filter(el => Object.values(el.sources).flat().includes(userStore.webName))
                dispatch(addArrayMyApps(appsWeb))
            } 
        })
    }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    // const appsByWeb = appsInWork.filter(el => Object.values(el.sources).flat().includes(userStore.webName))

    const findSource = (obj) => {
        const entries = Object.entries(obj)
        
        return entries.map((item) => {     
            if (item[1].includes(userStore.webName)) {
                return `${item[0]} `
            } else {
                return null
            }
        })
    }

    const modalHandler = (e) => {
        setModalActive(true)
        const app = myAppsStore.find(app => app._id === e.target.id)
        setFindApp(app)

        const appSources = Object.entries(app.sources).map(element => {
            if (element[1].includes(userStore.webName)) {
                return element[0]
            }
        })

        const modifySources = appSources.map(element => {
            return {
                value: element,
                label: element,
                color: '#000'
            }
        })
        setSources(modifySources.filter(el => el.value !== undefined))
    }

    const toFreeHandler = () => {
        updateAppToFree(findApp._id, userStore.webName, selectedSource).then(resp => {
            if (resp.message) {
                dispatch(updateMyApp(resp.app))
                alert(`Приложение ${findApp.appName} освобождено под ${selectedSource}`)
            } else {
                alert(`Ошибка`)
            }
        })
    }

    return (
        <div className={'form'}>
            {userStore.role === 'TechHelper' || userStore.role === 'CreoUnit' ?
                <h3>Нет доступа</h3>
            :
                <>
                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <td>Всего приложений в работе</td>
                                <td>{myAppsStore.length}</td>
                            </tr>
                            
                        </tbody>
                    </table>

                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>App</th>
                                <th>ID</th>
                                <th>OS</th>
                                <th>Source</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myAppsStore.map(item => {
                                return (
                                    <tr>
                                        <td>{item.appName}</td>
                                        <td>{item.partner}</td>
                                        <td>{item.platform}</td>
                                        <td>{findSource(item.sources)}</td>
                                        <td>
                                            <button id={item._id} onClick={modalHandler} className={'webBtnMyAppsEdit'}>Info</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {modalActive && (
                        <section className={'section'}>
                            <Modal 
                                active={modalActive}
                                setActive={setModalActive}>
                                    <div className={'popupContentMyApps'}>
                                        <TooltipImage item={findApp} />
                                        <div className={'discriptionMyAppsPopup'}>
                                            <div>
                                                <div className={'wrapperMyApps'}>
                                                    <div className={'appName'}>{findApp.appName}</div>
                                                </div>
                                                <div className={'wrapperMyApps'}>
                                                    <div className={'discription_mainMyApps'}>Release: {moment(findApp.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                                    <div className={'discription_mainMyApps'}>Naming: {findApp.naming}</div>
                                                    <div className={'discription_mainMyApps'}>Age: {findApp.age}</div>
                                                    <div className={'discription_mainMyApps'}><a className={'link_tg'} href={findApp.link} target="_blank" rel="noopener noreferrer">Link</a></div>
                                                    <div className={'discription_mainMyApps'}>Sources in work:</div>
                                                    <div className={'textMyApps'}>
                                                        <Select styles={colorStyles} menuPortalTarget={document.body} className={'smallOptions'} options={sources} onChange={selectedOption => setSelectedSource(selectedOption.value)} />
                                                    </div>
                                                    {selectedSource.length !== 0 && (
                                                        <button className={'webBtnMyApps'} onClick={toFreeHandler}>Освободить {findApp.appName} с источника {selectedSource}</button>
                                                    )}
                                                    {/* <button className={'webBtnAdd'}>Перенести приложение в БАН</button>
                                                    <button className={'webBtnAdd'}>Перенести приложение в СЛОМАННЫЕ</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </Modal>
                        </section>
                    )}
                </>
            }
        </div>
    );
}

export default MyApps
