import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayInteractives, resetCreos } from '../../store/interactivesReducer'
import { addArrayFilteredInteractives, resetFilteredInteractives } from '../../store/filteredInteractivesReducer'
import './InteractivesList.css'
import InteractivesItem from '../InteractivesItem/InteractivesItem'
import { getInteractives } from '../../http/interactivesApi'
import Loader from '../Loader'


const InteractivesList = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const interactivesStore = useSelector(state => state.interactives.interactives)
    const filteredInteractivesStore = useSelector(state => state.filteredInteractives.filteredInteractives)
    const [loading, setLoading] = useState(true)
    const [multiOptionTemplate, setMultiOptionTemplate] = useState([])
    const [multiOptionCreoName, setMultiOptionCreoName] = useState([])
    const [multiOptionTopic, setMultiOptionTopic] = useState([])
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [interactiveName, setInteractiveName] = useState('')
    const [geo, setGeo] = useState([])
    
    useEffect(() => {
        if (interactivesStore.length === 0) {
            getInteractives().then(data => {
                // const sortedArr = data.sort((a, b) => (a.creoName > b.creoName) ? -1 : (a.creoName === b.creoName) ? ((a.order > b.order) ? -1 : 1) : 1)
                // console.log(sortedArr)
                dispatch(addArrayInteractives(data))
                setLoading(false)
            })
        }
    }, [])

    // useEffect(() => {
    //     currentFilter()
    //     setTimeout(() => setLoading(false), 800)
    // }, [multiOptionTemplate, multiOptionCreoName, multiOptionTopic, multiOptionGeo])

    // useEffect(() => {
    //     const getOptionsFromDB = async () => {
    //         const getGeosFromDB = async () => {
    //             const data = await getGeos().then(geos => {
    //                 const templates = Array.from(new Set(creosStore.map(el => el.template)))
    //                 const modifyTemp = templates.map(element => {
    //                     return {
    //                         value: element,
    //                         label: element,
    //                         color: '#000'
    //                     }
    //                 })
    //                 setTemplates(modifyTemp)

    //                 const creoNames = creosStore.map(el => el.creoName)
    //                 const modifyCreoNames = creoNames.map(element => {
    //                     return {
    //                         value: element,
    //                         label: element,
    //                         color: '#000'
    //                     }
    //                 })
    //                 setCreoNames(modifyCreoNames.sort((a, b) => a.value > b.value ? 1 : -1))

    //                 const topics = Array.from(new Set(creosStore.map(el => el.hashes[0])))
    //                 const modifyTopics = topics.map(element => {
    //                     return {
    //                         value: element,
    //                         label: element,
    //                         color: '#000'
    //                     }
    //                 })
    //                 setHashes(modifyTopics)

    //                 const modifyGeo = geos[0].geos.map(element => {
    //                     return {
    //                         value: element,
    //                         label: element,
    //                         color: '#000'
    //                     }
    //                 })
    //                 setGeo(modifyGeo)
    //                 setFullGeo(modifyGeo)
    //             })
    //         }
    //         getGeosFromDB()
    //     }
    //     // if (user) {
    //         getOptionsFromDB()
    //         // setFilteredCreos(creosStore)
    //     // }
    // }, [creosStore])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    // const currentFilter = () => {
    //     const newArr = creosStore.filter(item => {
    //         const newObj = 
    //             {
    //                 template: item.template,
    //                 creoName: item.creoName,
    //                 hashes: item.hashes,
    //                 geo: item.geo,
    //                 order: item.order
    //             }

    //         const multiOption = multiOptionTemplate.concat(multiOptionCreoName).concat(multiOptionTopic)
            
    //         if (multiOption.some(el => Object.values(newObj).flat().includes(el))) {
    //             if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
    //                 return item
    //             } 
    //         } else {
    //             if (multiOption.length === 0) {
    //                 if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
    //                     return item
    //                 }
    //             } else {
    //                 if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
    //                     if (multiOption.some(el => Object.values(newObj).flat().includes(el))) {
    //                         return item
    //                     }
    //                 } 
    //             }
    //         }
    //     })

    //     const sortedArr = newArr.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
    //     dispatch(addArrayFilteredCreos(sortedArr))
        
    //     if (multiOptionGeo.length !== 0) {
    //         setGeo([])
    //     } else {
    //         setGeo(fullGeo)
    //     }

    // }

    // const sortItems = (e) => {
    //     switch (e.target.id) {
    //         case '1':
    //             setClickedSort2(false)
    //             setClickedSort3(false)
    //             if (!clickedSort1) {
    //                 setClickedSort1(!clickedSort1)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a, b) => a.geo.length - b.geo.length)
    //                 } else {
    //                     const data = creosStore.sort((a, b) => a.geo.length - b.geo.length)
    //                 }
    //             } else {
    //                 setClickedSort1(!clickedSort1)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a, b) => b.geo.length - a.geo.length)
    //                 } else {
    //                     const data = creosStore.sort((a, b) => b.geo.length - a.geo.length)
    //                 }
    //             }
    //             break;
    //         case '2':
    //             setClickedSort1(false)
    //             setClickedSort3(false)
    //             if (!clickedSort2) {
    //                 setClickedSort2(!clickedSort2)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a,b) => a.creoName > b.creoName ? 1 : -1)
    //                 } else {
    //                     const data = creosStore.sort((a,b) => a.creoName > b.creoName ? 1 : -1)
    //                 }
    //             } else {
    //                 setClickedSort2(!clickedSort2)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a, b) => a.creoName > b.creoName ? -1 : 1)
    //                 } else {
    //                     const data = creosStore.sort((a, b) => a.creoName > b.creoName ? -1 : 1)
    //                 }
    //             }
    //             break;
    //         case '3':
    //             setClickedSort1(false)
    //             setClickedSort2(false)
    //             if (!clickedSort3) {
    //                 setClickedSort3(!clickedSort3)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a,b) => a.order > b.order ? 1 : -1)
    //                 } else {
    //                     const data = creosStore.sort((a,b) => a.order > b.order ? 1 : -1)
    //                 }
    //             } else {
    //                 setClickedSort3(!clickedSort3)
    //                 if (filteredCreosStore.length !== 0) {
    //                     const data = filteredCreosStore.sort((a, b) => a.order > b.order ? -1 : 1)
    //                 } else {
    //                     const data = creosStore.sort((a, b) => a.order > b.order ? -1 : 1)
    //                 }
    //             }
    //             break;
    //         case '4':
    //             if (filteredCreosStore.length !== 0) {
    //                 const data = filteredCreosStore.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
    //                 dispatch(resetFilteredCreos())
    //                 dispatch(addArrayFilteredCreos(data))
    //             } else {
    //                 const data = creosStore.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
    //                 dispatch(resetCreos())
    //                 dispatch(addArrayCreos(data))
    //             }
    //             setClickedSort1(false)
    //             setClickedSort2(false)
    //             setClickedSort3(false)
    //             break;
    //         default:
    //             break;
    //     }
    // }

  return (
    <div className={'content'}>
        {userStore.role === 'TechHelper' || userStore.role === 'CreoUnit' ?
            <h3>Нет доступа</h3>
        :
            <>
                <div className={'list'}>
                    {loading ?
                        <Loader />
                    :
                        multiOptionTemplate.length !== 0 || multiOptionCreoName.length !== 0 || multiOptionTopic.length !== 0 || multiOptionGeo.length !== 0 ?
                            filteredInteractivesStore.map(elem => {
                                return (
                                    <InteractivesItem
                                        key={elem._id}
                                        item={elem}
                                        className={'itemCreo'}
                                        // multiOptionGeo={multiOptionGeo}
                                    />
                                )   
                            })
                        :
                            interactivesStore.map(elem => {
                                return (
                                    <InteractivesItem
                                        key={elem._id}
                                        item={elem}
                                        className={'itemCreo'}
                                        multiOptionGeo={multiOptionGeo}
                                    />
                                )   
                            })
                    }
                </div>
            </>
        }
    </div>
  )
}

export default InteractivesList
