import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useTg} from '../hooks/useTg'
import {addNewInteractive} from '../../http/interactivesApi'
import './Form.css'


const Interactive = () => {
    const userStore = useSelector(state => state.user.user)
    const [interactiveName, setInteractiveName] = useState('')
    const [interactive, setInteractive] = useState('')
    
    const {tg} = useTg()

    const onSendData = useCallback(() => {
        if (userStore.role === 'CreoLead' || userStore.role === 'Admin') {
            if (interactiveName === '') {
                alert('Заполни все поля')
            } else {
                addNewInteractive(interactiveName, interactive).then(data => {
                    data.action = 'addInteractive'
                    if (data.message) {
                        alert('Ошибка, такой шаблон уже добавлен')
                    } else {
                        tg.sendData(JSON.stringify(data))
                        alert('Интерактив добавлен')
                    }
                })
                
            }
        } else {
            alert('Нет доступа')
        }
    }, [interactiveName, interactive])

    const changeInteractiveName = (e) => {
        setInteractiveName(e.target.value)
    }

  return (
    <div className={'form'}>
        {userStore.role === 'Admin' ?
            <>
                <h3>Введите название интерактива</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Имя интерактива'}
                    value={interactiveName}
                    onChange={changeInteractiveName}
                />
                <h3>Файл</h3>
                <input 
                    accept=".html" 
                    className={'input'}
                    id="icon-button-file"  
                    type="file"
                    onChange={e => setInteractive(e.target.files[0])}
                />
                <button className={'webBtnAdd'} onClick={onSendData}>Добавить</button>
            </>
        :
            <h3>Нет доступа</h3>
        }
    </div>
  );
}

export default Interactive