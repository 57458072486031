import { React, useState, useEffect } from 'react'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayApps } from '../../store/appsReducer'
import './AppsList.css'
import AppItem from '../AppItem/AppItem'
import Navbar from '../NavBar/NavBar'
import { getAppsByPartner, getAvailableApps } from '../../http/appsApi'
import { getSources, getPartners, getAges, getPlatforms } from '../../http/adminApi'
import Loader from '../Loader'


const AppsList = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const appsStore = useSelector(state => state.apps.apps)
    const partnersStore = useSelector(state => state.partners.partners)
    const [loading, setLoading] = useState(true)
    const [filteredApps, setFilteredApps] = useState([])
    const [multiOption, setMultiOption] = useState([])
    const [sources, setSources] = useState([])
    const [options, setOptions] = useState([])
    
    useEffect(() => {
        if (appsStore.length === 0) {
            if (userStore.role === 'Admin' || userStore.role === 'TechHelper') {
                getAppsByPartner().then(data => {
                    if (data.length !== 0) {
                        const appsDB = data.map(el => el._id)
                        const check = appsStore.some(elem => appsDB.includes(elem._id))
                        if (!check) {
                            dispatch(addArrayApps(data))
                        } 
                    } 
                })
            } else {
                getAvailableApps().then(data => {
                    if (data.length !== 0) {
                        const appsDB = data.map(el => el._id)
                        const check = appsStore.some(elem => appsDB.includes(elem._id))
                        if (!check) {
                            dispatch(addArrayApps(data))
                        } 
                    } 
                })
            }
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        currentFilter()
    }, [multiOption])

    useEffect(() => {
        const getSourcesFromDB = async () => {
            const data = await getSources().then(sources => {
                const modifySources = sources.map(element => {
                    return {
                        value: element.name,
                        label: element.label,
                        color: '#000'
                    }
                })
                setSources(modifySources)
            })
        }
        getSourcesFromDB()
    }, [])


    useEffect(() => {
        const getOptionsFromDB = async () => {
            await getPartners().then(partners => {
                getAges().then(ages => {
                    getPlatforms().then(platforms => {
                        const optionsFromDB = partners.concat(ages).concat(platforms)

                        const modifyOptions = optionsFromDB.map(element => {
                            return {
                              value: element.partnerId || element.value,
                              label: element.value || element.label || `Partner ID ${element.partnerId}`,
                              color: '#000'
                            }
                        })
                        setOptions(modifyOptions)
                    })
                })
            })
        }
        getOptionsFromDB()
    }, [partnersStore])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

    const currentFilter = () => {
        const newArr = appsStore.filter(item => {
            if (multiOption.every(el => Object.values(item).includes(el))) {
                return item
            }
        })
        setFilteredApps(newArr)
    }
// console.log(multiOption)
  return (
        <div className={'content'}>
            {userStore.role === 'CreoUnit' ?
                <h3>Нет доступа</h3>
            :
                <>
                    <div className={'filters_apps'}>
                        <span className={'text'}>
                            <Select styles={colorStyles} className={'options'} options={options} onChange={selectedOption => setMultiOption(selectedOption.map(option => option.value))} isMulti />
                        </span>
                    </div>
                    <div className={'list'}>
                        {loading ?
                            <Loader />
                        :
                            multiOption.length !== 0 ?
                                filteredApps.map(elem => {
                                    return (
                                        <AppItem
                                            key={elem._id}
                                            item={elem}
                                            sources={sources}
                                            className={elem.status === 'По запросу' || elem.status === 'Можно лить' ? 'item' : elem.status === 'бан' || elem.status === 'Бан' ? 'itemBan' : 'itemBroke'}
                                        />
                                    )   
                                })
                            :
                                appsStore.map(elem => {
                                    return (
                                        <AppItem
                                            key={elem._id}
                                            item={elem}
                                            sources={sources}
                                            className={elem.status === 'По запросу' || elem.status === 'Можно лить' ? 'item' : elem.status === 'бан' || elem.status === 'Бан' ? 'itemBan' : 'itemBroke'}
                                        />
                                    )   
                                })
                        }
                    </div>
                </>
            }
        </div>
  );
}

export default AppsList
