import { LOGIN_ROUTE, 
         CREOSLIST_ROUTE, 
         APPS_ROUTE, 
         HOME_ROUTE, 
         ADD_APP_ROUTE, 
         ADD_CREO_ROUTE,
         ADD_INTERACTIVE_ROUTE,
         STATS_ROUTE,
         MOVING_ROUTE,
         DELETE_APP_ROUTE,
         ADMIN_ROUTE,
         MYCREOS_ROUTE,
         READYCREOS_ROUTE,
         MYAPPS_ROUTE,
         INTERACTIVES_ROUTE,
         GEOPOOL_ROUTE,
         CREO_TEMPLATES_ROUTE } from '../utils/consts'

import Auth from './Auth/Auth'
import creosList from './CreosList/CreosList'
import AppsList from './AppsList/AppsList'
import Home from './Home/Home'
import Form from './Form/Form'
import Creo from './Form/Creo'
import Interactive from './Form/Interactive'
import Statistics from './Statistics/Statistics'
import MoveApp from './Form/MoveForm'
import DeleteApp from './Form/DeleteForm'
import Admin from './Form/AdminForm'
import MyCreos from './MyCreos/MyCreos'
import ReadyCreos from './ReadyCreos/ReadyCreos'
import MyApps from './MyApps/MyApps'
import InteractivesList from './InteractivesList/InteractivesList'
import GeoPool from './GeoPool/GeoPool'
import CreoTemplates from './CreoTemplates/CreoTemplates'

export const authRoutes = [
   {
      path: APPS_ROUTE,
      Component: AppsList
   },

   {
      path: CREOSLIST_ROUTE,
      Component: creosList
   },

   {
      path: HOME_ROUTE,
      Component: Home
   },

   {
      path: ADD_APP_ROUTE,
      Component: Form
   },

   {
      path: ADD_CREO_ROUTE,
      Component: Creo
   },

   {
      path: STATS_ROUTE,
      Component: Statistics
   },

   {
      path: MOVING_ROUTE,
      Component: MoveApp
   },

   {
      path: DELETE_APP_ROUTE,
      Component: DeleteApp
   },

   {
      path: ADMIN_ROUTE,
      Component: Admin
   },

   {
      path: MYCREOS_ROUTE,
      Component: MyCreos
   },

   {
      path: READYCREOS_ROUTE,
      Component: ReadyCreos
   },

   {
      path: MYAPPS_ROUTE,
      Component: MyApps
   },

   {
      path: INTERACTIVES_ROUTE,
      Component: InteractivesList
   },

   {
      path: ADD_INTERACTIVE_ROUTE,
      Component: Interactive
   },

   {
      path: GEOPOOL_ROUTE,
      Component: GeoPool
   },

   {
      path: CREO_TEMPLATES_ROUTE,
      Component: CreoTemplates
   }
]


export const allRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    }
]