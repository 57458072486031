import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayGeoPool, resetGeoPool } from '../../store/geoPoolReducer'
import { getGeoPool } from '../../http/geoPoolApi'
import Loader from '../Loader'
import './GeoPool.css'


const GeoPool = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const geoPoolStore = useSelector(state => state.geoPool.geoPool)
    const userStore = useSelector(state => state.user.user)

    useEffect( () => {
        dispatch(resetGeoPool())
        getGeoPool().then(data => {
            if (data.length !== 0) {
                dispatch(addArrayGeoPool(data))
            } 
        })
        setTimeout(() => setLoading(false), 800)
    }, [])

    return (
        <div className={'form'}>
            {loading ?
                    <Loader />
                :
                    userStore.role === 'TechHelper' || userStore.role === 'CreoUnit' ?
                        <h3>Нет доступа</h3>
                    :
                        <>
                            <table className={'table'}>
                                <tbody>
                                    <tr>
                                        <td>Всего ГЕО</td>
                                        <td className={'tdTable'}>{geoPoolStore.length}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        <th>GEO</th>
                                        <th>PAYOUT RANGE</th>
                                        <th>TIER</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {geoPoolStore.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.geo}</td>
                                                <td>{item.payout}</td>
                                                <td>{item.tier}</td>
                                                {/* <td>
                                                    <button id={item._id} onClick={modalHandler} className={'webBtnMyAppsEdit'}>Info</button>
                                                </td> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
            }
        </div>
    );
}

export default GeoPool
