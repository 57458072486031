import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../../http/usersApi.js'
import { changeIsAuth } from '../../store/authReducer.js'
import { addOneUser, resetUser } from '../../store/userReducer.js'
import { HOME_ROUTE, APPS_ROUTE, ADD_APP_ROUTE, CREOSLIST_ROUTE } from '../../utils/consts.js'
import { useSelector, useDispatch } from 'react-redux'
import './Auth.css'


const Auth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userStore = useSelector(state => state.user.user)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    dispatch(resetUser())
  }, [])
console.log(userStore.role)
  const loginMain = async (event) => {
    event.preventDefault()
    try {
      await login(username, password).then(data => {
        if (data) {
          dispatch(addOneUser(data.authorizedUser))
          dispatch(changeIsAuth(true))
          switch (data.authorizedUser.role) {
            // case 'TechHelper':
            //     navigate(ADD_APP_ROUTE)
            //   break;
            case 'CreoUnit':
                navigate(CREOSLIST_ROUTE)
              break;
            default:
                navigate(APPS_ROUTE)
              break;
          }
        }
      })
      
    } catch (error) {
      alert('Неверные логин или пароль')
    }
  }
  
  return (
    <div className='wrapperAuth'>
      <div className="registration-cssave">
      <form>
          <h3 className="text-center">CF Agency</h3>
          <div className="form-group">
              <input 
                className="form-control itemAuth" 
                type="text" 
                name="username"  
                id="username" 
                placeholder="Логин"
                value = {username}
                onChange = {e => setUsername(e.target.value)}
                required />
          </div>
          <div className="form-group">
              <input 
                className="form-control itemAuth" 
                type="password" 
                name="Пароль" 
                id="password" 
                placeholder="Пароль" 
                value = {password}
                onChange = {e => setPassword(e.target.value)}
                required />
          </div>
          <div className="form-group wrapperAuth">
              <button className="btn" type="submit" onClick={loginMain}>Вход в аккаунт</button>
          </div>
      </form>
      </div>
    </div>
  )
}

export default Auth
