import { React, useState } from 'react'
import moment from 'moment/moment'
import TooltipHTML from '../Tooltip/TooltipHTML'
import './InteractivesItem.css'
import Modal from '../Modal/Modal'

const InteractivesItem = ({item, className}) => {
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)

  return (
    <div className={'element ' + className}>
        <div className={'wrapCreo'}>
            <div>
                <div className={'wrapperCreo'}>
                    <div className={'interactiveName'}>{item.interactiveName}</div>
                </div>
            </div>
            <TooltipHTML item={item} onClick={()=> setModalActive(true)} />
            <button className={'webBtnCreo'} onClick={()=> setModalActive(true)}>Смотреть</button>
        </div>
        {modalActive && (
            <section className={'section'}>
                <Modal 
                    active={modalActive}
                    setActive={setModalActive}>
                        <div className={'popupContentInt'}>
                            <iframe className='www' src={'https://mathieubois.com/interactive/' + item.interactive} width={'400px'} height={'300px'}></iframe>
                        </div>
                </Modal>
            </section>
        )}
    </div>
  )
}

export default InteractivesItem
